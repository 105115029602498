<template>
    <b-row class="match-height">
        <b-col cols="12">
            <b-jumbotron id="jumDts">
                <div class="container-fluid">
                    <div class="row justify-content-bewteen" v-if="this.name.length > 0">
                        <main class="col-sm-12 text-center pb-2">
                            <h1>Trip of {{ name }}</h1>
                        </main>
                        <main class="col-sm-6">
                            <div>
                                <span>Product: </span>
                                <span>{{ product_name }}</span>
                            </div>
                            <div>
                                <span>Amount: </span>
                                <span>{{ full_amount }}</span>
                            </div>
                            <div>
                                <span>Residence: </span> 
                                <span>{{ residence_literal }}</span>
                            </div>
                            <div>
                                <span>Destination: </span> 
                                <span>{{ destination_literal }}</span>
                            </div>
                        </main>
                        <main class="col-sm-6">
                            <div>
                                <span>Trip Duration: </span> 
                                <span>{{ start_date }}</span> - <span>{{ end_date }}</span>
                            </div>
                            <div>
                                <span>Date of Issue: </span>
                                <span>{{ issueDate }}</span>
                            </div>
                            <div>
                                <span>State: </span>
                                <span>{{ state }}</span>
                            </div>
                            <div>
                                <span>Email: </span>
                                <span>{{ email }}</span>
                            </div>
                        </main>
                    </div>
                    <div class="row" v-else>
                        <div class="col-sm-12 text-center">
                            <b-spinner variant="dark"></b-spinner>
                        </div>
                    </div>
                </div>
                
            </b-jumbotron>
        </b-col>

        <b-col
        lg="6"
        md="6"
        sm="12"
        >
            <b-card title="Client information">
                <b-card-body class="d-flex align-items-center justify-content-center" v-if="!this.customer_dts">
                        <b-spinner variant="dark"></b-spinner>
                </b-card-body>
                <b-card-body v-else>
                    <b-card-text class="d-block mt-1">
                        Full Name: {{ name }}
                    </b-card-text>
                    <b-card-text class="d-block mt-1">
                        Telephone: {{ telephone }}
                    </b-card-text>
                    <b-card-text class="d-block mt-1">
                        Address: {{ address }}
                    </b-card-text>
                </b-card-body>
            </b-card>
        </b-col>
        <b-col
        lg="6"
        md="6"
        sm="12"
        >
            <b-card title="Partner information">
                <b-card-body class="d-flex align-items-center justify-content-center" v-if="!this.customer_dts">
                        <b-spinner variant="dark"></b-spinner>
                </b-card-body>
                <b-card-body v-else>
                    <b-card-text class="d-block mt-1">
                        Company Name: {{ customer_dts.company_name }}
                    </b-card-text>
                    <b-card-text class="d-block mt-1">
                        Email: {{ customer_dts.email }}
                    </b-card-text>
                    <b-card-text class="d-block mt-1">
                        Active: {{ customer_dts.active }}
                    </b-card-text>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
    import { BRow, BCol, BCard, BJumbotron, BTable, BButton, BCardText, BCardTitle, BCardBody, BSpinner } from 'bootstrap-vue'
    export default {
        props:['name', 'product_name', 'full_amount', 'residence_literal', 'destination_literal', 'state', 'email', 'end_date', 'start_date', 'issueDate', 'customers', 'customer_dts', 'telephone', 'address'],
        components: {
            BRow,
            BCol,
            BCard,
            BJumbotron,
            BTable,
            BButton,
            BCardText,
            BCardTitle,
            BCardBody,
            BSpinner
        },
    }
</script>